function SpinnersModule(){
    this.addBtnSpinner = function (btn) {
        btn.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`
    }

    this.removeBtnSpinner = function (btn) {
        btn.innerHTML = btn.dataset.text
    }
}

module.exports = SpinnersModule
