import jQuery from "jquery";
import modal from 'bootstrap/js/src/modal';

const ErrorsModule = require('../errors'),
	SpinnersModule = require('../spinners');

let Errors = new ErrorsModule(),
	Spinners = new SpinnersModule();

const AdminTracksModule = (($) => {
	if (_ADMIN) {

		$.ajax({
			type: "GET",
			url: '/admin/artists',
			success: function(data) {
				data.forEach(artist => {
					$("#artists_list").append(`<option value="${artist.id}">${artist.name}</option>`);
				});
			},
		});


		if ($(".delete-track").length) {
			$(".delete-track").each(function() {
				$(this).on('click', function() {
					let id = $(this).data('id');

					if (confirm("Вы уверены, что хотите удалить?")) {
						$.ajax({
							type: "DELETE",
							url: "/admin/tracks/" + id,
							data: { _token: _CSRF },
							success: function() {
								alert('Успешно удалено!');
								$("#track_" + id).remove();
							},
							error: function(xhr) {
								Errors.getErr(xhr);
							},
						});
					}
				});
			})
		}

		$(".add-track").on('click', function(){
			clearForm();
			$("#trackModalLabel").html('Добавление трека');
			$('#trackModal').modal('show');
		});


		$(".edit-track").on('click', function(e) {
			let id = $(this).data('id');

			$.ajax({
				type: "GET",
				url: `/admin/tracks/${id}`,
				success: function(data) {

					$("#trackModalLabel").html('Редактирование трека');

					$('#trackModal input[name="id"]').val(id);
					$('#trackModal input[name="title"]').val(data.title);
					$('#artists_list').val(data.artist_id);
					$('#trackModal input[name="slug"]').val(data.slug);
					$('#trackModal textarea[name="biography"]').val(data.biography);

					$('#trackModal').modal('show');
				},
				error: function(xhr) {
					Errors.getErr(xhr);
				},
				complete: function () {

				},
			});
		});

		$("#trackModal form").submit(function(e) {
			e.preventDefault();

			let id = $("#trackModal input[name='id']").val(),
				url = "/admin/tracks",
				data = new FormData(this);

			if (id) {
				url = `/admin/tracks/${id}`;
				data.append('_method', 'PATCH');
			}

			$.ajax({
				method: 'POST',
				url: url,
				data: data,
				dataType: 'JSON',
				processData: false,
				contentType: false,
				success: function(data) {
					$('#trackModal').modal('hide');

					alert("Сохранено!")
					clearForm();
				},
				error: function(xhr) {
					Errors.getErr(xhr);
				},
				complete: function () {

				},
			});
		});
	}

	function clearForm()
	{
		$('#trackModal input[name="id"]').val("");
		$('#trackModal input[name="name"]').val("");
		$('#trackModal input[name="name_rus"]').val("");
		$('#trackModal input[name="slug"]').val("");
		$('#trackModal textarea[name="biography"]').val("");
		$('#edit_track_image_block').hide();
	}

})(jQuery);

export default AdminTracksModule