function UtilsModule(){
    // вешаем событие на поделиться
    this.initShare = function (){
        document.querySelectorAll("a.share-item").forEach(a => {
            a.addEventListener('click', () => {
                window.open(a.dataset.url, 'test', 'width=700, height=500, top='+((screen.height-800)/2)+',left='+((screen.width-700)/2)+', resizable=yes, scrollbars=no, status=yes');
            })
        })
    }
}

module.exports = UtilsModule
