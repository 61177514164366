function TracksModule(){
    this.loadTracksForPlayer = function () {

        let tracks = []

        document.querySelectorAll(".track-item[data-id]").forEach(elem => {
            tracks.push({
                id: elem.dataset.id,
                title: elem.dataset.title,
                artist: elem.dataset.artist,
                artwork: elem.dataset.artwork
            })
        })

        // если найдена хоть одна песня, то включаем плеер и подгружаем треклист
        if (tracks.length > 0) {
            document.querySelector(".player").classList.toggle("display-none")
            openTrackList(tracks)
            load_track(0)
        }
    }

    this.initDownloaderTimer = function (){
        if (_CURRENT_PAGE == 'track') {
            (function(d){
                let display = d.querySelector('#countdown .display')
                if (display) {
                    let timeLeft = parseInt(display.innerHTML)

                    let timer = setInterval(function(){
                        if (--timeLeft >= 0) {
                            display.innerHTML = timeLeft;
                        } else {
                            d.querySelector('#countdown h2').style.display = 'none'
                            d.querySelector('#countdown button').style.display = 'block'
                            clearInterval(timer)
                        }
                    }, 1000)
                }
            })(document)
        }
    }
}

module.exports = TracksModule
