require('bootstrap')

const TracksModule = require('./modules/tracks'),
	CommentsModule = require('./modules/comments'),
	UtilsModule = require('./modules/utils')

import AdminLyricsModule from './modules/admin/lyrics'
import AdminArtistsModule from './modules/admin/artists'
import AdminTrackModule from './modules/admin/tracks'

const Tracks = new TracksModule()
const Comments = new CommentsModule()
const Utils = new UtilsModule()

document.addEventListener('DOMContentLoaded', () => {
	Tracks.loadTracksForPlayer()
	Tracks.initDownloaderTimer()
	Comments.initCommentForm()
	Comments.initCommentsList()
	Utils.initShare()
})
