import jQuery from "jquery";
import modal from 'bootstrap/js/src/modal';

const ErrorsModule = require('../errors'),
	SpinnersModule = require('../spinners');

let Errors = new ErrorsModule(),
	Spinners = new SpinnersModule();

const AdminLyricsModule = (($) => {
	if (_ADMIN) {

		if ($(".delete-lyrics").length) {
			$(".delete-lyrics").each(function() {
				$(this).on('click', function() {
					let id = $(this).data('id');

					if (confirm("Вы уверены, что хотите удалить?")) {
						$.ajax({
							type: "DELETE",
							url: "/admin/lyrics/" + id,
							data: { _token: _CSRF },
							success: function() {
								alert('Успешно удалено!');
								$("#lyrics_" + id).remove();
							},
							error: function(xhr) {
								Errors.getErr(xhr);
							},
						});
					}
				});
			})
		}

		$(".add-lyrics").on('click', function(){
			clearForm();
			$("#lyricsModalLabel").html('Добавление текста песни');
			$('#lyricsModal').modal('show');
		});

		$(".edit-lyrics").on('click', function(e) {
			let id = $(this).data('id');

			$.ajax({
				type: "GET",
				url: `/admin/lyrics/${id}`,
				success: function(data) {

					$("#lyricsModalLabel").html('Редактирование текста песни');

					$('#lyricsModal input[name="id"]').val(id);
					$('#lyricsModal input[name="artist_name"]').val(data.artist_name);
					$('#lyricsModal input[name="title"]').val(data.title);
					$('#lyricsModal input[name="slug"]').val(data.slug);
					$('#lyricsModal textarea[name="text"]').val(data.text);

					$('#lyricsModal').modal('show');
				},
				error: function(xhr) {
					Errors.getErr(xhr);
				},
				complete: function () {

				},
			});
		});

		$("#lyricsModal form").submit(function(e) {
			e.preventDefault();

			let id = $("#lyricsModal input[name='id']").val(),
				url = "/admin/lyrics",
				method = "POST";


			if (id) {
				url = `/admin/lyrics/${id}`;
				method = "patch";
			}

			$.ajax({
				type: method,
				url: url,
				data: $(this).serialize(),
				success: function(data) {
					$('#lyricsModal').modal('hide');

					alert("Сохранено!")
					clearForm();
				},
				error: function(xhr) {
					Errors.getErr(xhr);
				},
				complete: function () {

				},
			});
		});

	}

	function clearForm()
	{
		$('#lyricsModal input[name="id"]').val("");
		$('#lyricsModal input[name="artist_name"]').val("");
		$('#lyricsModal input[name="title"]').val("");
		$('#lyricsModal input[name="slug"]').val("");
		$('#lyricsModal textarea[name="text"]').val("");
	}

})(jQuery);

export default AdminLyricsModule