import jQuery from "jquery";
import modal from 'bootstrap/js/src/modal';

const ErrorsModule = require('../errors'),
	SpinnersModule = require('../spinners');

let Errors = new ErrorsModule(),
	Spinners = new SpinnersModule();

const AdminArtistsModule = (($) => {
	if (_ADMIN) {

		if ($(".delete-artist").length) {
			$(".delete-artist").each(function() {
				$(this).on('click', function() {
					let id = $(this).data('id');

					if (confirm("Вы уверены, что хотите удалить?")) {
						$.ajax({
							type: "DELETE",
							url: "/admin/artists/" + id,
							data: { _token: _CSRF },
							success: function() {
								alert('Успешно удалено!');
								$("#artist_" + id).remove();
							},
							error: function(xhr) {
								Errors.getErr(xhr);
							},
						});
					}
				});
			})
		}

		$(".add-artist").on('click', function(){
			clearForm();
			$("#artistModalLabel").html('Добавление исполнителя');
			$('#artistModal').modal('show');
		});


		$(".edit-artist").on('click', function(e) {
			let id = $(this).data('id');

			$.ajax({
				type: "GET",
				url: `/admin/artists/${id}`,
				success: function(data) {

					$("#artistModalLabel").html('Редактирование исполнителя');

					$('#artistModal input[name="id"]').val(id);
					$('#artistModal input[name="name"]').val(data.name);
					$('#artistModal input[name="name_rus"]').val(data.name_rus);
					$('#artistModal input[name="slug"]').val(data.slug);
					$('#artistModal textarea[name="biography"]').val(data.biography);

					if (data.image) {
						$('#edit_artist_image_block').show();
						$('#edit_artist_image').attr("src",`/images/content/artists/200/${data.image}`);
					} else {
						$('#edit_artist_image_block').hide();
					}

					$('#artistModal').modal('show');
				},
				error: function(xhr) {
					Errors.getErr(xhr);
				},
				complete: function () {

				},
			});
		});

		$("#artistModal form").submit(function(e) {
			e.preventDefault();

			let id = $("#artistModal input[name='id']").val(),
				url = "/admin/artists",
				data = new FormData(this);

			if (id) {
				url = `/admin/artists/${id}`;
				data.append('_method', 'PATCH');
			}

			$.ajax({
				method: 'POST',
				url: url,
				data: data,
				dataType: 'JSON',
				processData: false,
				contentType: false,
				success: function(data) {
					$('#artistModal').modal('hide');

					alert("Сохранено!")
					clearForm();
				},
				error: function(xhr) {
					Errors.getErr(xhr);
				},
				complete: function () {

				},
			});
		});
	}

	function clearForm()
	{
		$('#artistModal input[name="id"]').val("");
		$('#artistModal input[name="name"]').val("");
		$('#artistModal input[name="name_rus"]').val("");
		$('#artistModal input[name="slug"]').val("");
		$('#artistModal textarea[name="biography"]').val("");
		$('#edit_artist_image_block').hide();
	}

})(jQuery);

export default AdminArtistsModule