const ErrorsModule = require('./errors'),
    SpinnersModule = require('./spinners')

let Errors = new ErrorsModule(),
    Spinners = new SpinnersModule()

function CommentsModule(){
    this.initCommentForm = function () {
        let commentsForm = document.querySelector(".new-comment form")

        if (commentsForm) {
            commentsForm.addEventListener('submit', (e) => {
                e.preventDefault()

                Spinners.addBtnSpinner(commentsForm.querySelector('.btn-save'))

                fetch('/comment/save', {
                    method: 'POST',
                    body: new FormData(commentsForm)
                }).then(data => data.json()).then(data => {
                    if (!data.success) {
                        Errors.catchErrors(data)
                    } else {
                        let userName = commentsForm.querySelector('input[name="name"]'),
                            userText = commentsForm.querySelector('textarea[name="text"]')

                        let now = new Date(),
                            commentDate = `${now.getHours()}:${now.getMinutes()}, ${now.getDate()}.${now.getMonth()+1}.${now.getFullYear()}`

                        let newComment = document.querySelector(".new-comment")
                        newComment.insertAdjacentHTML('afterend', `
                            <div class="comment">
                                <div class="comment-header">
                                    <strong class="me-auto">${userName.value}</strong>
                                    <small>${commentDate}</small>
                                </div>
                                <div class="comment-body">${userText.value}</div>
                            </div>
                        `)

                        userName.value = ''
                        userText.value = ''
                    }
                }).catch((e) => {
                    Errors.catchErrors('Ошибка сохранения комментария!')
                    console.log(e)
                }).finally(() => {
                    Spinners.removeBtnSpinner(commentsForm.querySelector('.btn-save'))
                });
            })
        }
    }

    this.initCommentsList = function () {
        let comments = document.querySelectorAll(".comment-delete")

        comments.forEach(comment => {
            comment.addEventListener('click', (e) => {

                if (confirm("Вы уверены?") == true) {
                    fetch(`/comment/${comment.dataset.id}/delete`, {
                        method: 'GET',
                    }).then(data => data.json()).then(data => {
                        if (!data.success) {
                            Errors.catchErrors(data)
                        } else {
                            document.getElementById(`comment_${comment.dataset.id}`).remove();
                        }
                    }).catch((e) => {
                        Errors.catchErrors('Ошибка удаления комментария!')
                        console.log(e)
                    }).finally(() => {
                    });
                }
            })
        })

        return;
        if (comments) {
            commentsForm.addEventListener('submit', (e) => {
                e.preventDefault()

                Spinners.addBtnSpinner(commentsForm.querySelector('.btn-save'))

                fetch('/comment/save', {
                    method: 'POST',
                    body: new FormData(commentsForm)
                }).then(data => data.json()).then(data => {
                    if (!data.success) {
                        Errors.catchErrors(data)
                    } else {
                        let userName = commentsForm.querySelector('input[name="name"]'),
                            userText = commentsForm.querySelector('textarea[name="text"]')

                        let now = new Date(),
                            commentDate = `${now.getHours()}:${now.getMinutes()}, ${now.getDate()}.${now.getMonth()+1}.${now.getFullYear()}`

                        let newComment = document.querySelector(".new-comment")
                        newComment.insertAdjacentHTML('afterend', `
                            <div class="comment">
                                <div class="comment-header">
                                    <strong class="me-auto">${userName.value}</strong>
                                    <small>${commentDate}</small>
                                </div>
                                <div class="comment-body">${userText.value}</div>
                            </div>
                        `)

                        userName.value = ''
                        userText.value = ''
                    }
                }).catch((e) => {
                    Errors.catchErrors('Ошибка сохранения комментария!')
                    console.log(e)
                }).finally(() => {
                    Spinners.removeBtnSpinner(commentsForm.querySelector('.btn-save'))
                });
            })
        }
    }
}

module.exports = CommentsModule
