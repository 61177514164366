function ErrorsModule(){
    this.getErrorsStr = function (arr) {
        if (typeof arr === "string" || arr instanceof String) {
            return arr
        }

        let errors = [],
            i = 0
        for (let prop in arr) {
            let str = arr[prop][0]
            if (typeof str === "string" || str instanceof String) {
                errors[i] = str
                i++
            }
        }
        return errors.join(' ')
    }

    this.catchErrors = function (errors) {
        alert(this.getErrorsStr(errors))
    }

    this.getErr = function(xhr) {
        if (xhr.responseJSON.error && xhr.responseJSON.error.length) return xhr.responseJSON.error;

        let errors = '';

        if (xhr.status == 200 || xhr.status == 422) {
            let arr = xhr.responseJSON.errors,
              i = 0;

            errors = [];

            for (let prop in arr) {
                let str = arr[prop][0];
                if ((typeof str === "string" || str instanceof String)) {
                    console.log(str)
                    errors[i] = str;
                    i++;
                }
            }

            errors = errors.join(' ');
        }

        if (xhr.status == 403) {
            if (xhr.responseJSON && xhr.responseJSON && xhr.responseJSON.message) {
                errors = xhr.responseJSON.message;
            } else {
                errors = 'У вас нет прав доступа к этой странице или действию!';
            }
        }

        if (xhr.status == 404) {
            errors = 'Страница или запрашиваемый объект не найдены!';
        }

        if (xhr.status == 500 ||  xhr.status == 502 ||  xhr.status == 503) {
            errors = 'Внутренняя ошибка! Пожалуйста, сообщите о ней в техподдержку!';
        }

        if (xhr.status == 401) {
            errors = 'Ошибка авторизации! Пожалуйста, войдите снова под своей учетной записью!';
        }

        if (xhr.status == 405) {
            errors = 'Данный метод запроса не поддерживается!';
        }

        if (xhr.status == 419) {
            errors = 'Ошибка авторизации! Обновите страницу и повторите попытку!';
        }

        alert(errors);

        return;
    }
}

module.exports = ErrorsModule
